/* eslint-disable global-require */
import universal from 'react-universal-component';
import { setupRouter } from '@cotyorg/ccx-server/router';
import { safeImport } from '@cotyorg/ccx-server/utils';

const ErrorPage = universal(import('./pages/Error'));
const NotFound = universal(import('./pages/NotFound'));
const Home = universal(import('./pages/Home'));
const Category = universal(import('./pages/Category'));
const Subcategory = universal(import('./pages/Subcategory'));
const TrendsLooks = universal(import('./pages/TrendsLooks'));
const Trends = universal(import('./pages/Trends'));
const LooksArticle = universal(import('./pages/LooksArticle'));
const RimmelYourEdges = universal(import('./pages/RimmelYourEdges'));
const Product = universal(import('./pages/Product'));
const Checkout = universal(import('./pages/Checkout'));
const About = universal(import('./pages/About'));
const Looks = universal(import('./pages/Looks'));
const SearchResults = universal(import('./pages/SearchResults'));
const New = universal(import('./pages/New'));
const MatchPerfection = universal(import('./pages/MatchPerfection'));
const AnimalTesting = universal(import('./pages/AnimalTesting'));
const CompetitionLondon = universal(import('./pages/CompetitionLondon'));
const Cyberbullying = universal(import('./pages/Cyberbullying'));
const FoundationFinder = universal(import('./pages/FoundationFinder'));
const LastingMatteCollection = universal(
  import('./pages/LastingMatteCollection')
);
const ExclusivePromotions = universal(import('./pages/ExclusivePromotions'));
const WhereToBuy = universal(import('./pages/WhereToBuy'));
const SerravalleOutlet = universal(import('./pages/SerravalleOutlet'));
const VirtualTryOn = universal(import('./pages/VirtualTryOn'));
const TermsAndConditions = universal(import('./pages/TermsAndConditions'));
const RimmelGirls = universal(import('./pages/RimmelGirls'));
const RimmelCreatorCrew = universal(import('./pages/RimmelCreatorCrew'));
const KindAndFree = universal(import('./pages/KindAndFree'));
const Page = universal(import('./pages/Page'));
const HalloweenChooseYourCrown = universal(
  import('./pages/HalloweenChooseYourCrown')
);
const Claims = universal(import('./pages/Claims'));
const RimmelPartner = universal(import('./pages/RimmelPartner'));
const MadameWeb = universal(import('./pages/MadameWeb'));
const OurIngredients = universal(import('./pages/OurIngredients'));
const GlowGiveawayTermsAndConditions = universal(
  import('./pages/GlowGiveawayTermsAndConditions')
);
const SweepstakesTermsAndConditions = universal(
  import('./pages/SweepstakesTermsAndConditions')
);

const routerConfig = {
  helperComponents: {
    ErrorComponent: ErrorPage,
  },

  defaultPages: {
    homeOverrides: {
      component: Home,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/home').default
      ),
    },
    notFoundOverrides: {
      component: NotFound,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/not-found').default
      ),
    },
    categoryOverrides: {
      component: Category,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/category').default
      ),
    },
    subcategoryOverrides: {
      component: Subcategory,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/subcategory').default
      ),
    },
    productOverrides: {
      component: Product,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/product').default
      ),
    },
  },

  additionalRoutes: [
    {
      path: '/:locale/about',
      exact: true,
      id: 'about',
      title: 'About',
      component: About,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/about').default
      ),
    },
    // This path is only to be used by ReactRouter Config, accessing this page directly is an edge case
    {
      path: '/:locale/trends-and-looks/',
      exact: true,
      id: 'trendslooks',
      title: 'TrendsLooks',
      component: TrendsLooks,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/trends-looks').default
      ),
    },
    {
      path: '/:locale/trends-and-looks/trends',
      exact: true,
      id: 'trends',
      title: 'Trends',
      component: Trends,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/trends').default
      ),
    },
    {
      path: '/:locale/trends-and-looks/looks/:slug',
      exact: true,
      id: 'looksArticle',
      title: 'Looks Article',
      component: LooksArticle,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/looks-article').default
      ),
    },
    {
      path: '/:locale/trends-and-looks/looks',
      exact: true,
      id: 'looks',
      title: 'Looks',
      component: Looks,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/looks').default
      ),
    },
    {
      path: '/:locale/trends-and-looks/rimmelyouredges',
      exact: true,
      id: 'rimmel-your-edges',
      title: 'Rimmel Your Edges',
      component: RimmelYourEdges,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/rimmel-your-edges').default
      ),
    },
    {
      path: '/:locale/error',
      exact: true,
      id: 'error',
      title: 'Error',
      component: ErrorPage,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/error').default
      ),
    },
    {
      path: '/:locale/checkout',
      exact: true,
      id: 'checkout',
      title: 'Checkout',
      component: Checkout,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/checkout').default
      ),
    },
    {
      path: '/:locale/search',
      exact: false,
      id: 'search-results-page',
      title: 'Search Results Page',
      component: SearchResults,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/search-results').default
      ),
    },
    {
      path: '/:locale/new',
      exact: true,
      id: 'new',
      title: 'New',
      component: New,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/new').default
      ),
    },
    {
      path: '/:locale/products/match-perfection',
      exact: true,
      id: 'match-perfection',
      title: 'Match Perfection',
      component: MatchPerfection,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/match-perfection').default
      ),
    },
    {
      path: '/:locale/animal-testing',
      exact: true,
      id: 'animal-testing',
      title: 'Animal Testing Page',
      component: AnimalTesting,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/animal-testing').default
      ),
    },
    {
      path: '/it-it/concorso-londra',
      exact: true,
      id: 'competition-london',
      title: 'Animal Testing Page',
      component: CompetitionLondon,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/competition-london').default
      ),
    },
    {
      path: '/:locale/beautycyberbullying',
      exact: true,
      id: 'beauty-cyberbullying',
      title: 'Beauty Cyberbullying',
      component: Cyberbullying,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/cyberbullying').default
      ),
    },
    {
      path: '/:locale/foundation-finder',
      exact: true,
      id: 'foundation-finder',
      title: 'Foundation Finder',
      component: FoundationFinder,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/foundation-finder').default
      ),
    },
    {
      path: '/:locale/lasting-matte-collection',
      exact: true,
      id: 'lasting-matte-collection',
      title: 'Lasting Matte Collection',
      component: LastingMatteCollection,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/lasting-matte-collection').default
      ),
    },
    {
      path: '/:locale/exclusive-promotions',
      exact: true,
      id: 'exclusive-promotions',
      title: 'Exclusive Promotions',
      component: ExclusivePromotions,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/exclusive-promotions').default
      ),
    },
    {
      path: '/:locale/where-to-buy',
      exact: true,
      id: 'where-to-buy',
      title: 'Where To Buy',
      component: WhereToBuy,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/where-to-buy').default
      ),
    },
    {
      path: '/cs-cz/kde-nakoupit',
      exact: true,
      id: 'where-to-buy',
      title: 'Where To Buy',
      component: WhereToBuy,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/where-to-buy').default
      ),
    },
    {
      path: '/:locale/rimmel-serravalle-outlet',
      exact: true,
      id: 'rimmel-serravalle-outlet',
      title: 'Serravalle Outlet',
      component: SerravalleOutlet,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/serravalle-outlet').default
      ),
    },
    {
      path: '/:locale/terms-and-conditions',
      exact: true,
      id: 'foundation-finder-terms-and-conditions',
      title: 'Foundation Finder Terms And Conditions',
      component: TermsAndConditions,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/terms-and-conditions').default
      ),
    },
    {
      path: '/en-gb/glow-giveaway-terms-and-conditions',
      exact: true,
      id: 'glow-giveaway-terms-and-conditions',
      title: 'Glow Giveaway Terms And Conditions',
      component: GlowGiveawayTermsAndConditions,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/glow-giveaway-terms').default
      ),
    },
    {
      path: '/:locale/sweepstakes-terms-and-conditions',
      exact: true,
      id: 'sweepstakes-terms-and-conditions',
      title: 'Sweepstakes Terms And Conditions',
      component: SweepstakesTermsAndConditions,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/sweepstakes-terms').default
      ),
    },
    {
      path: '/:locale/virtual-try-on',
      exact: true,
      id: 'virtual-try-on',
      title: 'Virtual Try On',
      component: VirtualTryOn,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/virtual-try-on-page').default
      ),
    },
    {
      path: '/:locale/rimmelgirls',
      exact: true,
      id: 'rimmel-girls',
      title: 'Rimmel Girls',
      component: RimmelGirls,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/rimmel-girls').default
      ),
    },
    {
      path: '/:locale/rimmelcreatorcrew',
      exact: true,
      id: 'rimmel-creator-crew',
      title: 'Rimmel Creator Crew',
      component: RimmelCreatorCrew,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/rimmel-creator-crew').default
      ),
    },
    {
      path: '/:locale/madame-web',
      exact: true,
      id: 'madame-web',
      title: 'Madame Web',
      component: MadameWeb,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/madame-web').default
      ),
    },
    {
      path: '/:locale/kind-and-free',
      exact: true,
      id: 'kind-and-free',
      title: 'Kind and Free',
      component: KindAndFree,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/kind-and-free').default
      ),
    },
    {
      path: '/:locale/halloween-chooseyourcrown',
      exact: true,
      id: 'halloween-chooseyourcrown',
      title: 'Halloween Choose Your Crown',
      component: HalloweenChooseYourCrown,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/halloween-choose-your-crown')
            .default
      ),
    },
    {
      path: '/:locale(en-gb|en-us)/halloween-the-oxford-circus',
      exact: true,
      id: 'halloween-chooseyourcrown',
      title: 'Halloween Choose Your Crown',
      component: HalloweenChooseYourCrown,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/halloween-choose-your-crown')
            .default
      ),
    },
    {
      path: '/:locale/product-claims',
      exact: true,
      id: 'product-claims',
      title: 'Product Claims',
      component: Claims,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/claims').default
      ),
    },
    {
      path: '/:locale/rimmel-partner-make-up-amici-21',
      exact: true,
      id: 'rimmel-partner',
      title: 'Rimmel Partner Make up Amici 21',
      component: RimmelPartner,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/rimmel-partner').default
      ),
    },
    {
      path: '/:locale/our-ingredients',
      exact: true,
      id: 'our-ingredients',
      title: 'Our Ingredients',
      component: OurIngredients,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/our-ingredients').default
      ),
    },
    // {
    //   path: '/en-gb/test-page',
    //   exact: true,
    //   id: 'page',
    //   title: 'Page',
    //   component: Page,
    //   buildPreloadedState: safeImport(
    //     () => require('../../server/hydrate/pages/page').default
    //   ),
    // },
    // {
    //   path: '/en-gb/maya-jama-astrology-collection',
    //   exact: true,
    //   id: 'page',
    //   title: 'Page',
    //   component: Page,
    //   buildPreloadedState: safeImport(
    //     () => require('../../server/hydrate/pages/page').default
    //   ),
    // },
  ],

  routesToRemove: [
    // 'example', // NB: there is no example route, so this won't remove anything - but this is the mechanism
  ],
};

export default setupRouter(routerConfig);
